import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  Datagrid,
  Filter,
  List,
  Loading,
  TextField,
  SelectInput,
  FunctionField,
} from "react-admin";
import { ComponentProps } from "../../providers/Interfaces";
import { exportScansDataToExcel } from "../../utils/Helper";

const years = [...new Array(new Date().getFullYear() - 2020)].map(
  (_: any, i: number) => (i + 2021).toString()
);

const yearsChoices: Array<{ id: string; name: string }> = [];
years.map((year) => yearsChoices.push({ id: year, name: year }));

const monthsChoices = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const CustomFilter = styled(Filter)({
  display: "inline-flex",
  height: "24px",
  padding: "6px 8px",
  alignItems: "center",
  "&.onefid-save .RaToolbar-desktopToolbar-325": {
    backgroundColor: "inherit",
    marginTop: 0,
  },
});

const StatisticsFilters = (props: any) => {
  return (
    <CustomFilter {...props}>
      <SelectInput label="Year" source="year" choices={yearsChoices} alwaysOn />
      <SelectInput
        label="Month"
        source="month"
        choices={monthsChoices}
        alwaysOn
      />
    </CustomFilter>
  );
};

const fields = ["id", "customer_uuid", "measurements_url", "scanmode"];

const germanDateTimeFormatter = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("de-DE"); // Format date to German (dd.mm.yyyy)
  const formattedTime = date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  }); // Format time to German (hh:mm:ss)

  return `${formattedDate}  ${formattedTime}`;
};

export const ScansOverviewList = (props: ComponentProps): JSX.Element => {
  const { permissions } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <List
      {...props}
      filters={<StatisticsFilters />}
      bulkActionButtons={false}
      exporter={(rec: any) => exportScansDataToExcel(rec, fields, "Scans")}
      sort={{ field: "id" }}
      title="Scans"
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="customer_uuid" />
        <FunctionField
          render={(rec: any) =>
            rec && rec.scanfiles && rec.scanfiles[0].last_processed_at
              ? germanDateTimeFormatter(rec.scanfiles[0].last_processed_at)
              : null
          }
        />
      </Datagrid>
    </List>
  );
};
